import {
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import adminRoutes from 'routes/admin.routes';
import tutorRoutes from 'routes/tutor.routes';
import generalRoutes from 'routes/general.routes';
import parentRoutes from 'routes/parent.routes';
import studentRoutes from 'routes/student.routes';
import adminHomeRoutes from 'routes/admin.welcome.routes';

const subdomain = window.location.hostname.split('.')[0];

let routes = [generalRoutes, parentRoutes, studentRoutes, tutorRoutes];

if (subdomain === 'admin') {
  routes = [adminRoutes, adminHomeRoutes];
} else if (subdomain === 'staging') {
  routes = [
    generalRoutes,
    parentRoutes,
    studentRoutes,
    tutorRoutes,
    adminRoutes,
  ];
}

const router = createBrowserRouter(createRoutesFromElements(routes));

export default router;
